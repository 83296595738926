import About from "./About/About";
import Contact from "./Contact/Contact";
import Hero from "./Hero/Hero";
import Products from "./Products/Products";
import Trusted from "./Trusted/Trusted";

function Home() {
  return (
    <section>
      <Hero />
      <About />
      <Trusted />
      <Products />
      <Contact />
    </section>
  );
}

export default Home;
