import Img from "../../../images/services/marketing.jpg";
import "../Services.css";

const Wholesale = () => {
  return (
    <section>
      <h1 className="Services_body-title">Wholesale Service</h1>
      <p className="Services_body-span">
        At BeledCosmetics, we understand the importance of offering high-quality
        cosmetic packaging solutions to businesses of all sizes. Our Wholesale
        Division is dedicated to providing bulk quantities of our premium
        packaging products at competitive prices, ensuring that you have
        everything you need to meet the demands of your customers.
      </p>
      <h2 className="Services_body-subtitle">
        Why Choose BeledCosmetics Wholesale:
      </h2>
      <h3 className="Services_body-category-title">Wide Range of Products:</h3>
      <p className="Services_body-category-text">
        selection of packaging options to suit your needs. Whether you're in the
        skincare, makeup, or personal care industry, we have the perfect
        packaging solution for you.
      </p>
      <h3 className="Services_body-category-title">High-Quality Materials:</h3>
      <p className="Services_body-category-text">
        Our packaging products are made from the finest materials, ensuring
        durability, functionality, and aesthetic appeal. We prioritize quality
        in every aspect of our production process, so you can trust that our
        products will meet your highest standards.
      </p>
      <h3 className="Services_body-category-title">Competitive Pricing:</h3>
      <p className="Services_body-category-text">
        We offer competitive wholesale pricing on all of our products, allowing
        you to maximize your profit margins while delivering exceptional value
        to your customers. With BeledCosmetics, you can save money without
        compromising on quality.
      </p>
      <h3 className="Services_body-category-title">Customization Options:</h3>
      <p className="Services_body-category-text">
        We understand that every brand is unique, which is why we offer
        customization options to help you stand out in the market. Whether you
        need custom colors, logos, or packaging designs, our team is here to
        bring your vision to life.
      </p>
      <h3 className="Services_body-category-title">Fast Turnaround Times:</h3>
      <p className="Services_body-category-text">
        We know that time is of the essence in the cosmetics industry, which is
        why we strive to provide fast turnaround times on all wholesale orders.
        With our efficient production process, you can rest assured that your
        order will be delivered on time, every time.
      </p>
      <div className="Services_body-image-container">
        <img className="Services_body-image" src={Img} alt="Wholesale" />
      </div>
    </section>
  );
};

export default Wholesale;
