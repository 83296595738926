export const articles = [
  {
    title: `Low MOQ? That's our specialty!`,
    text: `Minimum order quantities (MOQs) vary depending on factors such as
    material, capacity, size, and product type. For instance, for
    Small PE Ampoules, the MOQ is set at 20,000 pieces. Regular
    Acrylic, AS, or PET cream jars have an MOQ of 3,000 pieces, while
    PETG cosmetics bottles or HDPE/LDPE plastic bottles require a
    minimum order of 2,000 pieces.`,
    image: require("../images/blog/moq2.jpg"),
    link: "moq",
  },
  {
    title: `2024 Cosmetics and Packagings Trending in Beauty Industry.`,
    text: `Consumer demand for gentle, natural, and sustainable skincare will
    continue to grow. They are increasingly concerned about using
    products with natural ingredients, non-harmful chemicals, and
    environmentally friendly packaging. Consumers pay more attention
    to the ingredient lists of products and tend to choose cosmetics
    that are free from harmful chemicals such as sulfates, parabens,
    and synthetic fragrances. Additionally, they are also more
    conscious of the environmental impact of products and prefer to
    purchase items with recyclable packaging materials or products
    that incorporate sustainable packaging designs.`,
    image: require("../images/blog/beauty-trends.png"),
    link: "cosmetics_and_packagings_trending",
  },
  {
    title: `Bamboo Cosmetic Packaging: Unveiling the Eco-Friendly Cosmetic packaging  Revolution.`,
    text: `For millennia, bamboo has served as a versatile commodity, deeply ingrained in human civilization. Its symbolism varies across cultures, representing uprightness in China and friendship in India. Beyond its symbolic significance, bamboo has found utility across a spectrum of industries, from construction to textiles, owing to its sustainability and rapid growth. Recently, it has gained traction in the cosmetics sector as a sustainable packaging material.`,
    image: require("../images/blog/bamboo.jpg"),
    link: "bamboo_cosmetic_packaging",
  },
  {
    title: `When searching for suitable packaging materials for skincare
    products, attention should be paid to these details.`,
    text: `When searching for suitable packaging for new skincare products,
    it is important to focus on factors such as material and safety,
    product stability, protective performance, sustainability and
    environmental friendliness, reliability of the supply chain,
    packaging design and flexibility, as well as cost-effectiveness
    and operational feasibility. By considering these factors
    comprehensively, it is possible to select the most suitable
    packaging to meet the product's requirements and ensure an
    improvement in product quality and user experience.`,
    image: require("../images/blog/attention.jpg"),
    link: "attention_for_suitable_packaging_materials",
  },
  {
    title: `The Benefits of Airless Bottles in Skincare Packaging.`,
    text: `Many skincares on the market contain substances such as amino
    acids, proteins, and vitamins, which are sensitive to dust and
    bacteria and can easily become contaminated. Once contaminated,
    not only do they lose their intended effectiveness, but they can
    also become harmful. Airless bottles provide a solution by
    preventing the contents from coming into contact with air,
    effectively reducing the risk of product spoilage and bacterial
    growth. They also allow skincare manufacturers to minimize the use
    of preservatives and antibacterial agents, providing consumers
    with greater assurance.`,
    image: require("../images/blog/air-pump.jpg"),
    link: "airless_bottles_skincare_packaging",
  },
];
