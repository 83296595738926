import emailjs from "@emailjs/browser";

const SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
const SERVICE_KEY = process.env.REACT_APP_EMAIL_USER_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
const QUOTE_TEMPLATE_ID = process.env.REACT_APP_QUOTE_TEMPLATE_ID;

export const sendEmail = async (name, mobile, email, country, message) => {
  const templateParams = {
    name: name,
    mobile: mobile,
    email: email,
    country: country,
    message: message,
  };

  try {
    const response = await emailjs.send(
      SERVICE_ID,
      EMAIL_TEMPLATE_ID,
      templateParams,
      SERVICE_KEY
    );
    return response.status;
  } catch (error) {
    console.error("Error sending email:", error);
    return "ERROR_SENDING_EMAIL";
  }
};

export const sendQuote = async (
  name,
  mobile,
  email,
  country,
  message,
  list
) => {
  const templateParams = {
    name: name,
    mobile: mobile,
    email: email,
    country: country,
    message: message,
    list: list,
  };

  try {
    const response = await emailjs.send(
      SERVICE_ID,
      QUOTE_TEMPLATE_ID,
      templateParams,
      SERVICE_KEY
    );
    return response.status;
  } catch (error) {
    console.error("Error sending email:", error);
    return "ERROR_SENDING_EMAIL";
  }
};
