import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import { QuoteListProvider } from "./context/ProductsListContext";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e54016",
    },
    secondary: {
      main: "#3d73aa",
      contrastText: "#fff",
      light: "#000000",
      dark: "#000000",
    },
    third: {
      main: "#ffffff",
    },
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QuoteListProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </QuoteListProvider>
    </ThemeProvider>
  </React.StrictMode>
);
