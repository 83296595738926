import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "../Hero.css";
import "./Carousel-theme.css";
import "./Carousel.css";

const Carousel = () => {
  const navigate = useNavigate();

  const settings = {
    accessibility: true,
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
  };

  return (
    <Slider {...settings}>
      <div className="Carousel-container" aria-hidden="true">
        <div className="Carousel-image Carousel-image2" />
        <div className="Carousel-content-container">
          <h3 className="Carousel-title">Packaging Perfection</h3>
          <p className="Carousel-text" role="heading" aria-level={2}>
            Redefining Beauty in Cosmetics
          </p>
          <button
            className="Carousel-button"
            aria-hidden="true"
            tabIndex={-1}
            onClick={() => navigate("/products", { replace: false })}
          >
            View All Products
          </button>
        </div>
      </div>

      <div className="Carousel-container" aria-hidden="true">
        <div className="Carousel-image Carousel-image1" />
        <div className="Carousel-content-container">
          <h3 className="Carousel-title">Building Trust</h3>
          <p className="Carousel-text" role="heading" aria-level={2}>
            One Package at a Time
          </p>
          <button
            className="Carousel-button"
            aria-hidden="true"
            tabIndex={-1}
            onClick={() => navigate("/about", { replace: false })}
          >
            About Beled
          </button>
        </div>
      </div>

      <div className="Carousel-container" aria-hidden="true">
        <div className="Carousel-image Carousel-image3" />
        <div className="Carousel-content-container">
          <h3 className="Carousel-title">Connect With Us</h3>
          <p className="Carousel-text" role="heading" aria-level={2}>
            For Your Cosmetic Packaging Needs
          </p>
          <button
            className="Carousel-button"
            aria-hidden="true"
            tabIndex={-1}
            onClick={() => navigate("/contact", { replace: false })}
          >
            Contact us
          </button>
        </div>
      </div>
    </Slider>
  );
};

export default Carousel;
