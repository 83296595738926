import { Breadcrumbs, Link, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useQuoteList } from "../../../context/ProductsListContext";
import { useGetAllProducts } from "../../../hooks/useGetAllProducts";
import "./Product.css";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quoteList, addItem, removeItem } = useQuoteList();
  const { isLoading, error, data } = useGetAllProducts();
  const [pathList, setPathList] = useState([]);
  const [itemId, setItemId] = useState("");
  const [product, setProduct] = useState([]);
  const [productImage, setProductImage] = useState(null);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [likedProduct, setLikedProduct] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(150, 150, 150, 0.5)",
          fontSize: "50px",
          width: "20px",
          height: "100px",
          borderRadius: "8px",
          zIndex: "1000",
        }}
        onClick={onClick}
      />
    );
  }

  function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(150, 150, 150, 0.5)",
          fontSize: "50px",
          width: "20px",
          height: "100px",
          borderRadius: "8px",
          zIndex: "1000",
        }}
        onClick={onClick}
      />
    );
  }

  function getSettings() {
    return {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      row: 1,
      swipeToSlide: true,
      arrows: true,
      nextArrow: windowDimensions.width <= 500 ? "" : <SliderNextArrow />,
      prevArrow: windowDimensions.width <= 500 ? "" : <SliderPrevArrow />,
      variableWidth: true,
      // responsive: [
      //   {
      //     breakpoint: 1024,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 3,
      //       infinite: true,
      //       dots: true
      //     }
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2,
      //       initialSlide: 2
      //     }
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1
      //     }
      //   }
      // ]
    };
  }

  const settings = getSettings();

  useEffect(() => {
    const pathArray = location.pathname
      .split("/")
      .filter((item) => item !== "");
    setPathList(pathArray);
    setItemId(pathArray.slice(pathArray.length - 1).toString());
  }, [location]);

  useEffect(() => {
    setProduct([]);
    setProductImage([]);
    setRelatedProduct([]);
    setLikedProduct([]);

    async function fetchAllProducts(itemId) {
      if (data) {
        const item = data.filter((item) => item.id === itemId);
        setProduct(item[0]);
        setProductImage(item[0].images[0]);
        const category = item[0].category;

        setRelatedProduct(data.filter((item) => item.category === category));
        setLikedProduct(data.filter((item) => item.category !== category));
      }
    }

    if (itemId) {
      fetchAllProducts(itemId);
    }
  }, [data, itemId]);

  const handleNavigate = (item) => {
    navigate(`../Products/${item.category}/${item.id}`, { replace: false });
  };

  function renderData(data) {
    return Object.entries(data)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([key, value], index) => {
        if (typeof value === "object") {
          return (
            <div key={index} className="Product_text">
              {renderData(value)}
            </div>
          );
        } else {
          return (
            <p key={index} className="Product_text">
              <span className="Product_span">• {key}:</span> {value}
            </p>
          );
        }
      });
  }

  if (error) {
    return <h2 className="Product_title">Oops, Something went wrong..</h2>;
  }

  const handleClick = () => {
    const productId = product?.id;

    const index = quoteList.findIndex((item) => item.id === productId);

    if (index === -1) {
      addItem(product);
    } else {
      removeItem(index);
    }
  };

  return (
    <section className="Product">
      <div className="Product_path">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          {pathList.map((path, index) =>
            index < pathList.length - 1 ? (
              <NavLink
                key={index}
                className="Item_navlink"
                to={path === "Products" ? "/Products" : `/Products/${path}`}
                replace={true}
              >
                {path}
              </NavLink>
            ) : (
              <Typography key={index} color="text.primary">
                {path}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </div>

      <div className="Product_top">
        <div className="Product_image-container">
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"30vw"}
              height={"40vh"}
              sx={{ maxWidth: "1600px" }}
            />
          ) : (
            <img
              className="Product_top_main-image"
              src={productImage}
              alt={""}
            />
          )}
          <div>
            {product?.images?.length > 0 &&
              product?.images.map((image, index) => {
                return (
                  <img
                    className="Product_top_image"
                    key={index}
                    src={image}
                    alt=""
                    onClick={() => setProductImage(image)}
                  />
                );
              })}
          </div>
        </div>

        <div className="Product_details-container">
          <h1 className="Product_title">{product?.title}</h1>
          <p className="Product_text">
            <span className="Product_span">• SKU:</span>
            {product?.id}
          </p>
          {product?.additionalData && renderData(product.additionalData)}
          <p className="Product_text">
            <span className="Product_span">• MOQ:</span> {product?.MOQ}
          </p>
          <div className="Product_button-container">
            {quoteList.some((item) => item.id === product.id) ? (
              <button
                className="Product_button Product_button-remove"
                onClick={handleClick}
              >
                Remove from quote
              </button>
            ) : (
              <button className="Product_button" onClick={handleClick}>
                Add to quote
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="Product_related">
        <h2 className="Product_sections-title">Related items</h2>
        <div className="slider-container">
          {relatedProduct.length > 0 && (
            <Slider {...settings}>
              {relatedProduct.length > 0
                ? relatedProduct.map((item) => {
                    return (
                      <div className="Product_item" key={item?.id}>
                        {item?.images[0] ? (
                          <img
                            src={item?.images[0]}
                            alt="Product"
                            className="Item_image"
                            onClick={() => handleNavigate(item)}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={200}
                          />
                        )}
                        <h4
                          className="Product_item-title"
                          onClick={() => handleNavigate(item)}
                        >
                          {item?.title}
                        </h4>
                        <p className="Product_item-subtitle">MOQ {item?.MOQ}</p>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          )}
        </div>
      </div>
      <div className="Product_others">
        <h2 className="Product_sections-title">You may also like</h2>
        <div className="slider-container">
          {likedProduct.length > 0 && (
            <Slider {...settings}>
              {likedProduct.length > 0
                ? likedProduct?.map((item) => {
                    return (
                      <div className="Product_item" key={item?.id}>
                        {item?.images[0] ? (
                          <img
                            src={item?.images[0]}
                            alt="Product"
                            className="Item_image"
                            onClick={() => handleNavigate(item)}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={200}
                          />
                        )}
                        <h4
                          className="Product_item-title"
                          onClick={() => handleNavigate(item)}
                        >
                          {item?.title}
                        </h4>
                        <p className="Product_item-subtitle">MOQ {item?.MOQ}</p>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default Product;
