import { CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllProducts } from "../../../hooks/useGetAllProducts";
import "./Item.css";

const Item = ({ keyword }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const lowMoq = location?.state?.state;
  const { isLoading, error, data } = useGetAllProducts();
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    if (data) {
      if (lowMoq === "500") {
        let sortedData = [...data];
        sortedData.sort((a, b) => parseInt(a.MOQ, 10) - parseInt(b.MOQ, 10));
        setProductsList(sortedData);
        return;
      } else if (keyword === "") {
        let sortedData = [...data];
        sortedData.sort((a, b) => (a.category > b.category ? 1 : -1));
        setProductsList(sortedData);
        return;
      } else {
        setProductsList(
          data?.filter((product) => product.category === keyword)
        );
        return;
      }
    }
  }, [data, keyword, lowMoq]);

  const handleNavigate = (item) => {
    if (keyword === "") {
      navigate(`${item.category}/${item.id}`, { replace: false });
    } else {
      navigate(`${item.id}`, { replace: false });
    }
  };

  return (
    <section className="Products_items">
      {isLoading ? (
        <div className="Products_loader">
          <CircularProgress />
        </div>
      ) : productsList?.length > 0 ? (
        <div className="Products_items-container">
          {productsList.map((item) => (
            <div className="Item" key={item?.id}>
              {item?.images[0] && item?.title ? (
                <img
                  src={item?.images[0]}
                  alt={item?.title}
                  className="Item_image"
                  onClick={() => handleNavigate(item)}
                />
              ) : (
                <Skeleton variant="rectangular" width={200} height={200} />
              )}
              <h4 className="Item_title" onClick={() => handleNavigate(item)}>
                {item?.title}
              </h4>
              <p className="Item_subtitle">MOQ {item?.MOQ}</p>
            </div>
          ))}
        </div>
      ) : error ? (
        <p className="Products_Error">Oops, Somethig went wrong..</p>
      ) : (
        <p className="Products_Error">No products found!</p>
      )}
    </section>
  );
};

export default Item;
