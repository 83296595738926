import One from "../../../images/trusted/2080.png";
import Two from "../../../images/trusted/cleanskin.jpg";
import Three from "../../../images/trusted/jline.png";
import Four from "../../../images/trusted/moraz.png";
import Five from "../../../images/trusted/nd.png";
import Seven from "../../../images/trusted/shufersal.jpg";
import Six from "../../../images/trusted/valentina.png";
import "./Trusted.css";

const Trusted = () => {
  return (
    <section
      className="Trusted"
      role="contentinfo"
      aria-label="Trusted company"
    >
      <h2 className="Trusted_title">Trusted By The Best</h2>
      <div className="Trusted_slider-container">
        <div className="Trusted_slider">
          <img className="Trusted_image" src={One} alt="2080 Skin care" />
          <img className="Trusted_image" src={Two} alt="Clean skin club" />
          <img className="Trusted_image" src={Three} alt="J line" />
          <img
            className="Trusted_image"
            src={Four}
            alt="Moraz natural skin saver"
          />
          <img className="Trusted_image" src={Five} alt="N&D Nail design" />
          <img className="Trusted_image" src={Six} alt="Valentina makeup" />
          <img className="Trusted_image" src={Seven} alt="Shufersal" />
        </div>
        <div className="Trusted_slider">
          <img className="Trusted_image" src={One} alt="2080 Skin care" />
          <img className="Trusted_image" src={Two} alt="Clean skin club" />
          <img className="Trusted_image" src={Three} alt="J line" />
          <img
            className="Trusted_image"
            src={Four}
            alt="Moraz natural skin saver"
          />
          <img className="Trusted_image" src={Five} alt="N&D Nail design" />
          <img className="Trusted_image" src={Six} alt="Valentina makeup" />
          <img className="Trusted_image" src={Seven} alt="Shufersal" />
        </div>
      </div>
    </section>
  );
};

export default Trusted;
