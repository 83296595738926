import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import LmoqPopup from "./components/LmoqPopup/LmoqPopup";
import Navbar from "./components/Navbar/Navbar";
import Popup from "./components/Popup/Popup";
import QuotesList from "./components/QuotesList/QuotesList";
import About from "./pages/About/About";
import Article1 from "./pages/Blog/Article1/Article1";
import Article2 from "./pages/Blog/Article2/Article2";
import Article3 from "./pages/Blog/Article3/Article3";
import Article4 from "./pages/Blog/Article4/Article4";
import Article5 from "./pages/Blog/Article5/Article5";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Item from "./pages/Products/Item/Item";
import Product from "./pages/Products/Product/Product";
import Products from "./pages/Products/Products";
import ODM from "./pages/Services/ODM/ODM";
import OEM from "./pages/Services/OEM/OEM";
import Quality from "./pages/Services/Quality/Quality";
import Services from "./pages/Services/Services";
import Shipping from "./pages/Services/Shipping/Shipping";
import Wholesale from "./pages/Services/Wholesale/Wholesale";

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const hasPopupBeenShown = sessionStorage.getItem("popupShown");

    if (!hasPopupBeenShown) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
      }, 10000);

      sessionStorage.setItem("popupShown", "true");

      return () => clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const handlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="App">
      <Helmet>
        <title>BeledCosmetics - Cosmetic packaging wholesale</title>
        <meta
          name="description"
          content="Elevate your brand with BeledCosmetics' premium cosmetic packaging wholesale solutions. Superior quality, customization options, and competitive pricing. Partner with us today!"
        />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        {/* Google / Search Engine Tags */}
        <meta
          itemprop="name"
          content="BeledCosmetics - Cosmetic packaging wholesale"
        />
        <meta
          itemprop="description"
          content="Elevate your brand with BeledCosmetics' premium cosmetic packaging wholesale solutions. Superior quality, customization options, and competitive pricing. Partner with us today!"
        />
        <meta itemprop="image" content="./logo512.png" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="beledcosmetics.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="BeledCosmetics - Cosmetic packaging wholesale"
        />
        <meta
          property="og:description"
          content="Elevate your brand with BeledCosmetics' premium cosmetic packaging wholesale solutions. Superior quality, customization options, and competitive pricing. Partner with us today!"
        />
        <meta property="og:image" content="./logo512.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://beledcosmetics.com" />
        <meta name="twitter:creator" content="@Royal.prod" />
        <meta property="og:url" content="http://beledcosmetics.com" />
        <meta
          property="og:title"
          content="BeledCosmetics - Cosmetic packaging wholesale"
        />
        <meta
          property="og:description"
          content="Elevate your brand with BeledCosmetics' premium cosmetic packaging wholesale solutions. Superior quality, customization options, and competitive pricing. Partner with us today!"
        />
        <meta
          property="og:image"
          content="http://beledcosmetics.com/logo512.png"
        />
      </Helmet>
      <BrowserRouter>
        {showPopup && <LmoqPopup setShowPopup={setShowPopup} />}
        {isPopupOpen && <Popup handlePopup={handlePopup} />}

        <Drawer
          anchor={"right"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <QuotesList setIsDrawerOpen={setIsDrawerOpen} />
        </Drawer>

        <Navbar setIsPopupOpen={setIsPopupOpen} toggleDrawer={toggleDrawer} />

        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/products" element={<Products />}>
            <Route path="" element={<Item keyword={""} />} />
            <Route
              path="New-Arrivals"
              element={<Item keyword={"New-Arrivals"} />}
            />
            <Route path="Airless" element={<Item keyword={"Airless"} />} />
            <Route path="Bottles" element={<Item keyword={"Bottles"} />} />
            <Route path="Jars" element={<Item keyword={"Jars"} />} />
            <Route path="Makeup" element={<Item keyword={"Makeup"} />} />
            <Route path="Bamboo" element={<Item keyword={"Bamboo"} />} />
          </Route>
          <Route path="/products/:category/:id" element={<Product />} />
          <Route path="/services" element={<Services />}>
            <Route path="/services/oem" element={<OEM />} />
            <Route path="/services/odm" element={<ODM />} />
            <Route path="/services/wholesale" element={<Wholesale />} />
            <Route path="/services/quality-control" element={<Quality />} />
            <Route path="/services/shipping" element={<Shipping />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route
            path="/blog/cosmetics_and_packagings_trending"
            element={<Article1 />}
          />
          <Route
            path="/blog/airless_bottles_skincare_packaging"
            element={<Article2 />}
          />
          <Route
            path="/blog/attention_for_suitable_packaging_materials"
            element={<Article3 />}
          />
          <Route path="/blog/moq" element={<Article4 />} />
          <Route
            path="/blog/bamboo_cosmetic_packaging"
            element={<Article5 />}
          />
          <Route path="/contact" element={<Contact />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
