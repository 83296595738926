import { useNavigate } from "react-router-dom";
import Airless from "../../../images/products/Airless.jpg";
import Bamboo from "../../../images/products/Bamboo.jpg";
import Bottles from "../../../images/products/Bottles.jpg";
import Lotion from "../../../images/products/Jars.jpg";
import Makeup from "../../../images/products/Makeup.jpg";
import New from "../../../images/products/New Arrivals.jpg";
import "./Products.css";

const Products = () => {
  const navigate = useNavigate();

  return (
    <section
      id="products"
      className="Products-page"
      role="contentinfo"
      aria-label="Products"
    >
      <div className="Products-container">
        <div className="Products-categories">
          <h1 className="Products-page-title">Cosmetic Packaging Solution</h1>
          <p className="Products-page-subtitle">
            Explore our cosmetic packaging products, featuring a diverse range
            of bottles, jars, Makeup, and more. Each product is crafted with
            quality and innovation in mind, ensuring your beauty products are
            showcased in style.
          </p>
          <div className="Products-categories-container">
            <button
              id="aa"
              className="Products-categories-item"
              onClick={() =>
                navigate("/Products/New-Arrivals", { replace: false })
              }
            >
              <img
                className="Products-categories-image"
                src={New}
                alt="New arivals"
              />
              <p className="Products-categories-text">New arivals</p>
            </button>

            <button
              id="bb"
              className="Products-categories-item"
              onClick={() => navigate("/Products/Airless", { replace: false })}
            >
              <img
                className="Products-categories-image"
                src={Airless}
                alt="Airless"
              />
              <p className="Products-categories-text">Airless</p>
            </button>

            <button
              id="cc"
              className="Products-categories-item"
              onClick={() => navigate("/Products/Makeup", { replace: false })}
            >
              <img
                className="Products-categories-image"
                src={Makeup}
                alt="Makeup"
              />
              <p className="Products-categories-text">Makeup</p>
            </button>

            <button
              id="dd"
              className="Products-categories-item"
              onClick={() => navigate("/Products/Jars", { replace: false })}
            >
              <img
                className="Products-categories-image"
                src={Lotion}
                alt="Jars"
              />
              <p className="Products-categories-text">Jars</p>
            </button>

            <button
              id="ee"
              className="Products-categories-item"
              onClick={() => navigate("/Products/Bamboo", { replace: false })}
            >
              <img
                className="Products-categories-image"
                src={Bamboo}
                alt="Bamboo"
              />
              <p className="Products-categories-text">Bamboo</p>
            </button>

            <button
              id="ff"
              className="Products-categories-item"
              onClick={() => navigate("/Products/Bottles", { replace: false })}
            >
              <img
                className="Products-categories-image"
                src={Bottles}
                alt="Bottles"
              />
              <p className="Products-categories-text">Bottles</p>
            </button>
          </div>

          <button
            className="Products-button"
            onClick={() => navigate("/Products", { replace: false })}
          >
            View All Products →
          </button>
        </div>
      </div>
    </section>
  );
};

export default Products;
