import Img from "../../../images/services/oem-process.jpg";
import "../Services.css";

const OEM = () => {
  return (
    <section>
      <h1 className="Services_body-title">OEM Service</h1>
      <p className="Services_body-span">
        In the competitive world of cosmetics, standing out is essential. Your
        brand's packaging plays a pivotal role in capturing attention and
        conveying your product's essence. Enter BeledCosmetics, your trusted
        partner in OEM (Original Equipment Manufacturer) services, empowering
        your brand to shine in the beauty industry.
      </p>
      <p className="Services_body-span">
        At BeledCosmetics, we understand the significance of packaging in the
        cosmetics realm. With our OEM services, we offer a seamless journey from
        concept to creation, ensuring your vision transforms into tangible,
        captivating packaging solutions.
      </p>
      <h2 className="Services_body-subtitle">
        Partnering with BeledCosmetics can elevate your brand:
      </h2>
      <h3 className="Services_body-category-title">Tailored Solutions:</h3>
      <p className="Services_body-category-text">
        Our team works closely with you to understand your brand identity,
        target audience, and product specifications, ensuring that the packaging
        we create reflects your unique vision.
      </p>
      <h3 className="Services_body-category-title">Innovative Designs:</h3>
      <p className="Services_body-category-text">
        Stay ahead of the curve with our cutting-edge designs crafted by
        experienced professionals who are well-versed in the latest trends and
        innovations in cosmetic packaging.
      </p>
      <h3 className="Services_body-category-title">Quality Assurance:</h3>
      <p className="Services_body-category-text">
        Rest assured, your products will be encased in packaging that not only
        looks stunning but also maintains its integrity and functionality,
        thanks to our stringent quality control measures and state-of-the-art
        facilities.
      </p>
      <h3 className="Services_body-category-title">Cost-Effectiveness:</h3>
      <p className="Services_body-category-text">
        Benefit from competitive pricing without compromising on quality,
        maximizing your return on investment and allowing you to allocate
        resources effectively.
      </p>
      <h3 className="Services_body-category-title">Timely Delivery:</h3>
      <p className="Services_body-category-text">
        We understand the importance of meeting deadlines in the fast-paced
        beauty industry. With BeledCosmetics, you can rely on timely delivery,
        enabling you to seize market opportunities and stay ahead of the
        competition.
      </p>
      <p className="Services_body-span">
        Choose BeledCosmetics as your OEM partner and embark on a journey of
        success, where your brand's potential is fully realized through
        exceptional packaging solutions crafted with passion and expertise.
      </p>
      <div className="Services_body-image-container">
        <img className="Services_body-image" src={Img} alt="Wholesale" />
      </div>
    </section>
  );
};

export default OEM;
