import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pump from "../../images/blog/air-pump.jpg";
import Details from "../../images/blog/attention.jpg";
import Bamboo from "../../images/blog/bamboo.jpg";
import Trend from "../../images/blog/beauty-trends.png";
import MOQ from "../../images/blog/moq2.jpg";
import "./Blog.css";

const Blog = () => {
  const navigate = useNavigate();

  const handleClick = (article) => {
    navigate(`${article}`, { replace: false });
  };

  return (
    <section className="Blog">
      <div className="Blog_header">
        <h1 className="Blog_header-title">FAQ</h1>
        <p className="Blog_header-subtitle">
          Welcome to the vibrant world of cosmetics packaging innovation!
        </p>
      </div>
      <p className="Blog_header-text">
        At BeledCosmetics, we are dedicated to redefining beauty standards
        through our exceptional range of packaging solutions. Dive into our blog
        to uncover the latest trends, insights, and inspirations driving the
        cosmetics industry forward. Whether you're an industry professional,
        beauty enthusiast, or aspiring entrepreneur, our blog is your gateway to
        discovering the artistry and innovation behind every bottle. Join us as
        we explore the intersection of creativity, sustainability, and
        functionality in the world of cosmetics packaging.
      </p>
      <div className="Blog_body">
        <div className="Blog-container">
          <img
            src={MOQ}
            alt="Packaging Trending"
            className="Blog_image"
            onClick={() => handleClick("moq")}
          />
          <div className="Blog_content-container">
            <h2
              className="Blog_content-title"
              onClick={() => handleClick("moq")}
            >
              Low MOQ? That's our specialty!
            </h2>
            <span className="Blog_content-span">
              Minimum order quantities (MOQs) vary depending on factors such as
              material, capacity, size, and product type. For instance, for
              Small PE Ampoules, the MOQ is set at 20,000 pieces. Regular
              Acrylic, AS, or PET cream jars have an MOQ of 3,000 pieces, while
              PETG cosmetics bottles or HDPE/LDPE plastic bottles require a
              minimum order of 2,000 pieces.
            </span>
            <Button
              variant="contained"
              color="secondary"
              className="Blog_button"
              onClick={() => handleClick("moq")}
            >
              Read more
            </Button>
          </div>
        </div>

        <div className="Blog-container">
          <img
            src={Trend}
            alt="Packaging Trending"
            className="Blog_image"
            onClick={() => handleClick("cosmetics_and_packagings_trending")}
          />
          <div className="Blog_content-container">
            <h2
              className="Blog_content-title"
              onClick={() => handleClick("cosmetics_and_packagings_trending")}
            >
              2024 Cosmetics and Packagings Trending in Beauty Industry.
            </h2>
            <span className="Blog_content-span">
              Consumer demand for gentle, natural, and sustainable skincare will
              continue to grow. They are increasingly concerned about using
              products with natural ingredients, non-harmful chemicals, and
              environmentally friendly packaging. Consumers pay more attention
              to the ingredient lists of products and tend to choose cosmetics
              that are free from harmful chemicals such as sulfates, parabens,
              and synthetic fragrances. Additionally, they are also more
              conscious of the environmental impact of products and prefer to
              purchase items with recyclable packaging materials or products
              that incorporate sustainable packaging designs.
            </span>
            <Button
              variant="contained"
              color="secondary"
              className="Blog_button"
              onClick={() => handleClick("cosmetics_and_packagings_trending")}
            >
              Read more
            </Button>
          </div>
        </div>

        <div className="Blog-container">
          <img
            src={Bamboo}
            alt="Packaging Trending"
            className="Blog_image"
            onClick={() => handleClick("bamboo_cosmetic_packaging")}
          />
          <div className="Blog_content-container">
            <h2
              className="Blog_content-title"
              onClick={() => handleClick("bamboo_cosmetic_packaging")}
            >
              Bamboo Cosmetic Packaging: Unveiling the Eco-Friendly Cosmetic
              packaging Revolution.
            </h2>
            <span className="Blog_content-span">
              For millennia, bamboo has served as a versatile commodity, deeply
              ingrained in human civilization. Its symbolism varies across
              cultures, representing uprightness in China and friendship in
              India. Beyond its symbolic significance, bamboo has found utility
              across a spectrum of industries, from construction to textiles,
              owing to its sustainability and rapid growth. Recently, it has
              gained traction in the cosmetics sector as a sustainable packaging
              material.
            </span>
            <Button
              variant="contained"
              color="secondary"
              className="Blog_button"
              onClick={() => handleClick("bamboo_cosmetic_packaging")}
            >
              Read more
            </Button>
          </div>
        </div>

        <div className="Blog-container">
          <img
            src={Pump}
            alt="Packaging Trending"
            className="Blog_image"
            onClick={() => handleClick("airless_bottles_skincare_packaging")}
          />
          <div className="Blog_content-container">
            <h2
              className="Blog_content-title"
              onClick={() => handleClick("airless_bottles_skincare_packaging")}
            >
              The Benefits of Airless Bottles in Skincare Packaging.
            </h2>
            <span className="Blog_content-span">
              Many skincares on the market contain substances such as amino
              acids, proteins, and vitamins, which are sensitive to dust and
              bacteria and can easily become contaminated. Once contaminated,
              not only do they lose their intended effectiveness, but they can
              also become harmful. Airless bottles provide a solution by
              preventing the contents from coming into contact with air,
              effectively reducing the risk of product spoilage and bacterial
              growth. They also allow skincare manufacturers to minimize the use
              of preservatives and antibacterial agents, providing consumers
              with greater assurance.
            </span>
            <Button
              variant="contained"
              color="secondary"
              className="Blog_button"
              onClick={() => handleClick("airless_bottles_skincare_packaging")}
            >
              Read more
            </Button>
          </div>
        </div>

        <div className="Blog-container">
          <img
            src={Details}
            alt="Packaging Trending"
            className="Blog_image"
            onClick={() =>
              handleClick("attention_for_suitable_packaging_materials")
            }
          />
          <div className="Blog_content-container">
            <h2
              className="Blog_content-title"
              onClick={() =>
                handleClick("attention_for_suitable_packaging_materials")
              }
            >
              When searching for suitable packaging materials for skincare
              products, attention should be paid to these details.
            </h2>
            <span className="Blog_content-span">
              When searching for suitable packaging for new skincare products,
              it is important to focus on factors such as material and safety,
              product stability, protective performance, sustainability and
              environmental friendliness, reliability of the supply chain,
              packaging design and flexibility, as well as cost-effectiveness
              and operational feasibility. By considering these factors
              comprehensively, it is possible to select the most suitable
              packaging to meet the product's requirements and ensure an
              improvement in product quality and user experience.
            </span>
            <Button
              variant="contained"
              color="secondary"
              className="Blog_button"
              onClick={() =>
                handleClick("attention_for_suitable_packaging_materials")
              }
            >
              Read more
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
