import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

const API_KEY = process.env.REACT_APP_FIRESTORE_API_KEY;
const AUTH_DOMAIN = process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN;
const PROJECT_ID = process.env.REACT_APP_FIRESTORE_PROJECT_ID;
const STORAGE_BUCKET = process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET;
const MESSAGING_ID = process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID;
const APP_ID = process.env.REACT_APP_FIRESTORE_APP_ID;

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_ID,
  appId: APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getAllProducts = async () => {
  try {
    const valRef = collection(db, "products");
    const docs = await getDocs(valRef);
    const productslist = docs.docs.map((doc) => doc.data());

    return productslist;
  } catch (error) {
    console.error("Error getting all products:", error);
    throw error;
  }
};

export const getProductByCategory = async () => {
  try {
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("isBestSeller", "==", true));
    const querySnapshot = await getDocs(q);

    const products = [];
    querySnapshot.forEach((doc) => {
      products.push(doc.data());
    });

    if (products.length > 0) {
      return products;
    } else {
      console.log("No matching documents.");
      return null;
    }
  } catch (error) {
    console.error("Error getting product:", error);
    throw error;
  }
};
