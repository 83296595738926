import Img from "../../../images/services/quality.png";
import "../Services.css";

const Quality = () => {
  return (
    <section>
      <h1 className="Services_body-title">Quality Control Service</h1>
      <p className="Services_body-span">
        At BeledCosmetics, we prioritize quality at every stage of production,
        ensuring that your cosmetics packaging meets the highest standards of
        excellence. From the initial phases to the final product, our rigorous
        quality control measures guarantee satisfaction and reliability.
      </p>
      <h3 className="Services_body-category-title">Starting Production:</h3>
      <p className="Services_body-category-text">
        When production begins, our dedicated quality control team meticulously
        inspects raw materials and components to ensure they meet our strict
        quality criteria. We carefully evaluate factors such as durability,
        safety, and compatibility with cosmetic products, ensuring that only the
        finest materials are used in the manufacturing process.
      </p>
      <h3 className="Services_body-category-title">Finishing Production:</h3>
      <p className="Services_body-category-text">
        As production nears completion, our quality control experts conduct
        thorough inspections of the finished packaging products. Every aspect,
        from structural integrity to aesthetic appeal, undergoes meticulous
        scrutiny to ensure adherence to your specifications and our quality
        standards. Any deviations or defects are promptly addressed to maintain
        the integrity of the final product.
      </p>
      <h3 className="Services_body-category-title">
        QC Report for Shipping Approval:
      </h3>
      <p className="Services_body-category-text">
        Prior to shipping, we provide a comprehensive test report detailing the
        results of our quality control assessments. This report serves as a
        transparent record of the quality assurance measures undertaken during
        production. We understand the importance of your confidence in the
        products you receive, which is why we offer this test report for your
        review.
      </p>
      <h3 className="Services_body-category-title">Client Approval Process:</h3>
      <p className="Services_body-category-text">
        Upon receipt of the test report, we await your confirmation before
        proceeding with shipping. We value your input and want to ensure that
        you are fully satisfied with the quality of the goods. Only upon your
        approval of the test report will we proceed with shipping, guaranteeing
        that you receive products that meet your expectations and standards.
      </p>

      <p className="Services_body-span">
        Trust BeledCosmetics for quality control excellence throughout the
        production process. With our commitment to meticulous inspection and
        transparency, we ensure that your cosmetics packaging is of the highest
        quality, providing you with peace of mind and confidence in your brand's
        offerings.
      </p>
      <div className="Services_body-image-container">
        <img className="Services_body-image" src={Img} alt="Wholesale" />
      </div>
    </section>
  );
};

export default Quality;
