import MOQ from "../../../images/blog/moq2.jpg";
import "../Article.css";
import More from "../More/More";

const Article4 = () => {
  return (
    <section className="Article">
      <div className="Article_header">
        <h1 className="Article_header-title">Low MOQ? That's our specialty!</h1>
        <img
          className="Article_header-image"
          src={MOQ}
          alt="Trending in Beauty background"
        />
      </div>

      <div className="Article_body">
        <h3 className="Article_body-title">MOQ</h3>
        <p className="Article_body-text">
          Minimum order quantities (MOQs) vary depending on factors such as
          material, capacity, size, and product type. For instance, for Small PE
          Ampoules, the MOQ is set at 20,000 pieces. Regular Acrylic, AS, or PET
          cream jars have an MOQ of 3,000 pieces, while PETG cosmetics bottles
          or HDPE/LDPE plastic bottles require a minimum order of 2,000 pieces.
        </p>

        <h3 className="Article_body-title">Flexibility & Customization</h3>
        <p className="Article_body-text">
          At BeledCosmetics, we understand the importance of flexibility and
          customization. Whether you're launching a new product line or
          replenishing your existing inventory, we're committed to accommodating
          your specific requirements.
        </p>
        <p className="Article_body-text">
          While many factories impose minimum order quantities (MOQs) upwards of
          10,000 units, we recognize the evolving needs of our customers and the
          dynamic market landscape. That's why we've made it our mission to
          stand out by offering low MOQs as a core part of our service and
          expertise.
        </p>

        <p className="Article_body-text">
          With a keen understanding of the industry, we proudly set our MOQ at
          just 2,000 pieces for a diverse array of our products. This commitment
          to flexibility reflects our dedication to serving businesses of all
          sizes, from emerging brands to established enterprises.
        </p>
        <p className="Article_body-text">
          In addition to our low MOQ service, we understand the paramount
          importance of branding elements such as logos and colors on packaging.
          We take pride in offering customizable design options that cater to
          every aspect of our customers' vision. Whether it's achieving a
          desired texture like Soft Touch, Matt, Shiney, Mixed colors, gradient
          color and beyond, we're dedicated to bringing our customers' creative
          concepts to life.
        </p>
        <p className="Article_body-text">
          For more detailed information about our product packaging options,
          specifications, and customization possibilities, please don't hesitate
          to get in touch with us. Our dedicated team is here to assist you
          every step of the way. Contact us today to explore how BeledCosmetics
          can elevate your skincare packaging experience.
        </p>
      </div>

      <div className="Article_More">
        <h4 className="Article_More-title">More to read:</h4>
        <More />
      </div>
    </section>
  );
};

export default Article4;
