import Carousel from "./Carousel/Carousel";
import "./Hero.css";

const Hero = () => {
  return (
    <section
      className="hero"
      id="home"
      role="main"
      aria-label="Cover"
      aria-hidden="true"
      tabIndex={-1}
    >
      <Carousel />
    </section>
  );
};

export default Hero;
