import Factory1 from "../../images/factory/factory1.jpg";
import Factory3 from "../../images/factory/factory3.png";
import Factory4 from "../../images/factory/factory4.jpg";
import Factory5 from "../../images/factory/factory5.png";
import Factory6 from "../../images/factory/factory6.png";
import Factory7 from "../../images/factory/factory7.png";
import Factory8 from "../../images/factory/factory8.png";
import "./About.css";

const About = () => {
  return (
    <section>
      <div className="About_header">
        <h1 className="About_cover-title">Welcome to BeledCosmetics:</h1>
        <h2 className="About_cover-subtitle">
          Your Cosmetics Packaging Supplier In China
        </h2>
      </div>
      <div className="About_body-container">
        <div className="About_content-container">
          <h2 className="About_header-title">About Us</h2>
          <p className="About_header-subtitle">
            BeledCosmetics was founded in 2014, specializing in research and
            development, as well as the manufacturing and processing of
            cosmetic, beauty, and personal packaging. Our focus lies in
            producing a comprehensive range of plastic bottles, including PE,
            PET, Airless, Acrylic, Glass, and Jars. Committed to the ethos of
            "Special, Fashion, Environment friendly," we've pioneered the
            development of eco-friendly products such as PCR-PE bottles, PCR-PET
            bottles, aluminum caps, and bamboo plastic assembly caps.
          </p>
        </div>
        <img className="About_header-image" src={Factory3} alt="" />
      </div>

      <div className="About_body-container">
        <img className="About_header-image" src={Factory8} alt="" />
        <div className="About_content-container">
          <h2 className="About_header-title">Our Mission</h2>
          <p className="About_header-subtitle">
            At BeledCosmetics, we boast a team of highly experienced technical
            personnel and utilize state-of-the-art automation machinery and
            high-end quality inspection equipment. Our dedication is to deliver
            products of the utmost quality while meticulously crafting our
            clients' brands. Over the years, we've earned the praise and trust
            of our business partners through our sincere service and exceptional
            product quality. As a preferred tube packaging supplier for numerous
            renowned skincare brands and makeup products of internet
            celebrities, we've forged partnerships across Southeast Asia,
            Europe, and America.
          </p>
        </div>
      </div>

      <div className="About_body-container">
        <div className="About_content-container">
          <h2 className="About_header-title">Crafting Beauty & Brilliance</h2>
          <p className="About_header-subtitle">
            Continuing on our path of dedication and excellence, BeledCosmetics
            remains steadfast in our commitment to accompany you in creating
            beauty and brilliance for the future.
          </p>
        </div>
        <img className="About_header-image" src={Factory4} alt="" />
      </div>

      <div className="About_body">
        <img src={Factory1} alt="Factory 1" className="About_big-image" />
        <div className="About_body-photos">
          <img src={Factory5} alt="Factory 5" className="About_small-image" />
          <img src={Factory6} alt="Factory 3" className="About_small-image" />
          <img src={Factory7} alt="Factory 4" className="About_small-image" />
        </div>
      </div>
    </section>
  );
};

export default About;
