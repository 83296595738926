import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Services.css";

const Services = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState("wholesale");

  useEffect(() => {
    const pathArray = location.pathname
      .split("/")
      .filter((item) => item !== "");

    if (pathArray.length > 0) {
      const lastPath = pathArray[pathArray.length - 1];
      setValue(lastPath);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    navigate(`/services/${newValue}`, { replace: true });
  };

  return (
    <section className="Services">
      <div className="Services_header">
        <h1 className="Services_header-title">Our Services</h1>
      </div>
      <div className="Services_body">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "0 0 0",
          }}
        >
          <Tabs
            className="Services_tabs"
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab wrapped value="wholesale" label="wholesale Service" />
            <Tab wrapped value="oem" label="OEM Service" />
            <Tab wrapped value="odm" label="ODM Service" />
            <Tab wrapped value="quality-control" label="Quality Service" />
            <Tab wrapped value="shipping" label="Shipping Service" />
          </Tabs>
        </Box>
        <Outlet />

        <p className="Services_p">
          Whether you're an enthusiast in skincare, a beauty influencer, or an
          established brand seeking to expand your product offerings,
          BeledCosmetics is your trusted partner for private label solutions.
          Contact us today to discover how we can assist you in bringing your
          brand to life.
        </p>
      </div>
    </section>
  );
};

export default Services;
