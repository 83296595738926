import Img from "../../../images/services/odm-process.png";
import "../Services.css";

const ODM = () => {
  return (
    <section>
      <h1 className="Services_body-title">ODM Service</h1>
      <p className="Services_body-span">
        ODM stands for original design manufacturer. Under this model, the brand
        company needn’t design and R&D. The factory will finish the whole
        process from design to maintenance, and provide several existing styles
        of products for the brand company to choose from.
      </p>
      <h3 className="Services_body-category-title">
        Efficiency and Convenience:
      </h3>
      <p className="Services_body-category-text">
        Save time and resources by leveraging BeledCosmetics' streamlined ODM
        process. From concept development to production, our efficient workflow
        ensures a hassle-free experience, allowing you to focus on other aspects
        of your business while we bring your packaging visions to life.
      </p>
      <h3 className="Services_body-category-title">Customization Options:</h3>
      <p className="Services_body-category-text">
        We understand that every brand has its own identity and requirements.
        With BeledCosmetics ODM services, you have the flexibility to customize
        designs to suit your brand aesthetic and product specifications. From
        colors and materials to finishing touches, we work closely with you to
        create packaging that perfectly complements your brand image.
      </p>
      <p className="Services_body-span">
        Unlock your brand's potential with BeledCosmetics ODM services and
        embark on a journey of creativity and innovation in the cosmetics
        industry. Let us be your partner in bringing your packaging dreams to
        reality.
      </p>
      <div className="Services_body-image-container">
        <img className="Services_body-image" src={Img} alt="Wholesale" />
      </div>
    </section>
  );
};

export default ODM;
