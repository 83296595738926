import BackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendQuote } from "../../API/emailApi";
import { useQuoteList } from "../../context/ProductsListContext";
import "./QuotesList.css";

const QuotesList = ({ setIsDrawerOpen }) => {
  const navigate = useNavigate();
  const { quoteList, clearList, removeItem } = useQuoteList();
  const [activeStep, setActiveStep] = useState(1);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsError(false);
    setErrorMessage("");
  }, [activeStep]);

  const handleNavigate = (item) => {
    navigate(`../Products/${item.category}/${item.id}`, { replace: false });
    setIsDrawerOpen(false);
  };

  const handleDelete = (item) => {
    const productId = item?.id;
    const index = quoteList.findIndex((item) => item.id === productId);
    removeItem(index);

    if (quoteList.length - 1 === 0) {
      setIsDrawerOpen(false);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);

    if (!name || !email || !country || !message) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Please fill all the required fields!");
      return;
    } else if (!isValidEmail(email)) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Email is invalid!");
      return;
    } else {
      const list = quoteList.map((item) => item.id);

      await sendQuote(name, mobile, email, country, message, list).then(
        (response) => {
          if (response === 200) {
            setIsDone(true);
            clearList();
          } else {
            setIsError(true);
            setErrorMessage("Oops, Something went wrong..");
          }
        }
      );
    }
  };

  return (
    <div className="Drawer">
      {activeStep === 1 ? (
        <>
          <div>
            <div className="Drawer_form-container">
              <IconButton
                aria-label="close"
                color="primary"
                onClick={() => setIsDrawerOpen(false)}
                sx={{ alignSelf: "flex-start" }}
              >
                <CloseIcon />
              </IconButton>
              <h1 className="Drawer_form-title">Quote Request</h1>
              <Divider />
            </div>
            <div className="Drawer_items-container">
              {quoteList.map((quote) => {
                return (
                  <div className="Drawer_item" key={quote.id}>
                    <img
                      src={quote.images[0]}
                      alt={quote.title}
                      className="Drawer_item-image"
                      onClick={() => handleNavigate(quote)}
                    />
                    <div onClick={() => handleNavigate(quote)}>
                      <span className="Drawer_item-title">{quote.title}</span>
                      <p className="Drawer_item-mpq">MOQ: {quote.MOQ}</p>
                    </div>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => handleDelete(quote)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className="Contact_contact-box_button"
            onClick={() => setActiveStep(2)}
          >
            Continue
          </Button>
        </>
      ) : isDone ? (
        <div className="Drawer_form-container">
          <IconButton
            aria-label="close"
            color="primary"
            onClick={() => setIsDrawerOpen(false)}
            sx={{ alignSelf: "flex-start" }}
          >
            <CloseIcon />
          </IconButton>
          <h2 className="Drawer_form-title">Success!</h2>
          <Divider />
          <p className="Drawer_form-text">
            One of our packaging experts will reach out to you shortly to
            discuss your needs.
          </p>
        </div>
      ) : (
        <div className="Drawer_form-container">
          <IconButton
            aria-label="back"
            color="primary"
            onClick={() => setActiveStep(1)}
            sx={{ alignSelf: "flex-start" }}
          >
            <BackIcon />
          </IconButton>
          <h2 className="Drawer_form-title">Fill your details</h2>
          <Divider />
          <p className="Drawer_form-text">
            One of our packaging experts will reach out to you shortly to
            discuss your needs.
          </p>
          <TextField
            required="true"
            aria-required="true"
            size="small"
            id="fullName"
            label="Full Name"
            color="secondary"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            disabled={isLoading ? true : false}
          />
          <TextField
            required="false"
            aria-required="false"
            size="small"
            id="phoneNumber"
            label="Phone Number"
            color="secondary"
            variant="outlined"
            onChange={(e) => setMobile(e.target.value)}
            disabled={isLoading ? true : false}
          />
          <TextField
            required="true"
            aria-required="true"
            size="small"
            id="emailAddress"
            label="Email"
            color="secondary"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading ? true : false}
          />
          <TextField
            required="true"
            aria-required="true"
            size="small"
            id="country"
            label="Country"
            color="secondary"
            variant="outlined"
            className="Contact_contact-box_input"
            onChange={(e) => setCountry(e.target.value)}
            disabled={isLoading ? true : false}
          />
          <TextField
            required="true"
            aria-required="true"
            size="small"
            id="massage"
            label="Massage"
            color="secondary"
            variant="outlined"
            className="Contact_contact-box_input"
            multiline
            rows={4}
            onChange={(e) => setMessage(e.target.value)}
            disabled={isLoading ? true : false}
          />
          {isError && <p className="Drawer_form-error">{errorMessage}</p>}
          <Button
            variant="contained"
            color="secondary"
            className="Contact_contact-box_button"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading ? true : false}
            startIcon={isLoading ? <CircularProgress size={25} /> : ""}
          >
            {isLoading ? "Sending..." : "Submit"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuotesList;
