import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { sendEmail } from "../../API/emailApi";
import EmailIcon from "../../images/icons/logo-email.png";
import FacebookIcon from "../../images/icons/logo-facebook.png";
import InstagramIcon from "../../images/icons/logo-instagram.png";
import LinkedInIcon from "../../images/icons/logo-linkedin.png";
import WhatsappIcon from "../../images/icons/logo-whatsapp.png";
import YouTubeIcon from "../../images/icons/logo-youtube.png";
import "./Contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);

    if (!name || !email || !country || !message) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Please fill all the required fields!");
      return;
    } else if (!isValidEmail(email)) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Email is invalid!");
      return;
    } else {
      await sendEmail(name, mobile, email, country, message).then(
        (response) => {
          if (response === 200) {
            setIsDone(true);
          } else {
            setIsError(true);
            setErrorMessage("Oops, Something went wrong..");
          }
        }
      );
    }
  };

  return (
    <section className="Contact">
      <div className="Contact_header">
        <h1 className="Contact_header-title">Contact Us</h1>
        <p className="Contact_header-subtitle">
          We would be happy to meet you and discover more about your business.
        </p>
      </div>

      <div className="Contact_contact-container">
        <Box component="form" className="Contact_contact-box">
          <h2 className="Contact_contact-box_title">We’ll follow up</h2>
          <Divider />
          <p>
            One of our packaging experts will reach out to you shortly to
            discuss your needs.
          </p>
          {isDone ? (
            <p>Message sent successfully!</p>
          ) : (
            <>
              <TextField
                required="true"
                aria-required="true"
                size="small"
                id="fullName"
                label="Full Name"
                color="secondary"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                disabled={isLoading ? true : false}
              />
              <TextField
                required="false"
                aria-required="false"
                size="small"
                id="phoneNumber"
                label="Phone Number"
                color="secondary"
                variant="outlined"
                onChange={(e) => setMobile(e.target.value)}
                disabled={isLoading ? true : false}
              />
              <TextField
                required="true"
                aria-required="true"
                size="small"
                id="emailAddress"
                label="Email"
                color="secondary"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading ? true : false}
              />
              <TextField
                required="true"
                aria-required="true"
                size="small"
                id="country"
                label="Country"
                color="secondary"
                variant="outlined"
                className="Contact_contact-box_input"
                onChange={(e) => setCountry(e.target.value)}
                disabled={isLoading ? true : false}
              />
              <TextField
                required="true"
                aria-required="true"
                size="small"
                id="massage"
                label="Massage"
                color="secondary"
                variant="outlined"
                className="Contact_contact-box_input"
                multiline
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
                disabled={isLoading ? true : false}
              />
              {isError && <p className="Drawer_form-error">{errorMessage}</p>}
              <Button
                variant="contained"
                color="secondary"
                className="Contact_contact-box_button"
                onClick={(e) => handleSubmit(e)}
                disabled={isLoading ? true : false}
                startIcon={isLoading ? <CircularProgress size={25} /> : ""}
              >
                {isLoading ? "Sending..." : "Submit"}
              </Button>
            </>
          )}
        </Box>

        <div className="Contact_contact-secondary">
          <h3 className="Contact_contact-box_title-secondary">
            We're eager to engage with you!
          </h3>
          <aside className="footer__list-container">
            <p className="footer_text">
              Your feedback, thoughts, and questions are invaluable to us. Reach
              out via our contact form, and rest assured, we're committed to
              responding promptly.
            </p>
            <p className="footer_text">
              Join our social circle today, and be part of a dynamic community
              where inspiration, innovation, and connection thrive. We can't
              wait to share the journey with you!
            </p>
            <div className="footer_social-container">
              <a
                href="https://www.youtube.com/@BeledCosmetics"
                target="_blank"
                rel="noreferrer"
                aria-label="YouTube page"
              >
                <img className="material-icon" src={YouTubeIcon} alt="" />
              </a>
              <a
                href="https://www.facebook.com/BeledCosmetics"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook page"
              >
                <img src={FacebookIcon} alt="" className="material-icon" />
              </a>
              <a
                href="https://www.instagram.com/beledcosmetics__"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram page"
              >
                <img src={InstagramIcon} alt="" className="material-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/beledcosmetics/"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn page"
              >
                <img src={LinkedInIcon} alt="" className="material-icon" />
              </a>
              <a
                href="mailto:sales@beledcosmetics.com"
                target="_blank"
                rel="noreferrer"
                aria-label="Mail link"
                className="Contact_icon"
              >
                <img className="material-icon" src={EmailIcon} alt="" />
              </a>
              <a
                href="https://wa.me/+8618018796760"
                target="_blank"
                rel="noreferrer"
                aria-label="Whatsapp link"
                className="Contact_icon"
              >
                <img className="material-icon" src={WhatsappIcon} alt="" />
              </a>
            </div>
            <div className="Contact_contactDetails"></div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default Contact;
