import Img from "../../../images/services/delivery.webp";
import "../Services.css";

const Shipping = () => {
  return (
    <section>
      <h1 className="Services_body-title">
        Shipping worldwide with BeledCosmetics
      </h1>
      <p className="Services_body-span">
        BeledCosmetics has you covered with a variety of shipping choices.
        Whether you're an ecommerce seller or a cosmetics distributor, our
        comprehensive guide will help you evaluate the pros and cons of each
        shipping method, ensuring you select the best freight shipment method
        for your requirements. Let's dive in without delay.
      </p>

      <h2 className="Services_body-subtitle">Shipping Modes:</h2>
      <h3 className="Services_body-category-title">Courier Service</h3>
      <p className="Services_body-category-text">
        A courier service swiftly transports packages and documents from one
        location to another, offering reliable and convenient delivery options
        to meet diverse needs.
      </p>
      <h3 className="Services_body-category-title">Air freight</h3>
      <p className="Services_body-category-text">
        Also known as air cargo, it’s a preferred method for transporting goods
        by plane. It’s ideal for small, delicate, and expensive packages.
      </p>
      <h3 className="Services_body-category-title">Sea freight</h3>
      <p className="Services_body-category-text">
        It’s a method of transporting goods through carrier ships after packing
        them into containers and loading them onto vessels.
      </p>
      <h3 className="Services_body-category-title">DTD (Including Taxes)</h3>
      <p className="Services_body-category-text">
        Shipping service ensures seamless delivery from the sender's location
        directly to the recipient's doorstep. With DTD, you can enjoy
        convenience, reliability, and peace of mind as your package is safely
        transported, tracked, and delivered with care.
      </p>
      <p className="Services_body-span">
        Are you in need of bulk cosmetic packaging solutions, such as cases,
        tubes, bottles, and jars, for your beauty products? Unsure about the
        best shipping options available? Look no further than BeledCosmetics. We
        offer comprehensive shipping services tailored to meet the unique needs
        of cosmetic packaging exporters like you. Here's why shipping with
        BeledCosmetics is the right choice:
      </p>
      <h3 className="Services_body-category-title">Expert Guidance:</h3>
      <p className="Services_body-category-text">
        With years of experience in the cosmetics packaging industry,
        BeledCosmetics provides expert guidance on selecting the optimal
        shipping mode for your specific requirements. Whether you're shipping
        delicate bottles or sturdy cases, we help you navigate through the
        available options to find the most suitable solution.
      </p>
      <h3 className="Services_body-category-title">Efficient Delivery:</h3>
      <p className="Services_body-category-text">
        At BeledCosmetics, we understand the importance of timely delivery in
        the beauty industry. Our shipping services are designed to ensure prompt
        and efficient transportation of your orders, whether you opt for air
        express, air freight, or sea freight. You can trust us to deliver your
        cosmetic packaging products to your desired destination on time, every
        time.
      </p>
      <h3 className="Services_body-category-title">
        Cost-Effective Solutions:
      </h3>
      <p className="Services_body-category-text">
        We prioritize cost-effectiveness without compromising on the quality of
        our shipping services. BeledCosmetics offers competitive rates for air
        express, air freight, and sea freight options, allowing you to maximize
        your budget while ensuring reliable delivery of your cosmetic packaging
        consignments.
      </p>
      <p className="Services_body-span">
        Choose BeledCosmetics as your shipping partner and experience the
        convenience, reliability, and affordability of our comprehensive
        shipping solutions. With our expert guidance, efficient delivery, and
        cost-effective options, we ensure that your cosmetic packaging products
        reach their destination safely and on schedule.
      </p>
      <div className="Services_body-image-container">
        <img className="Services_body-image" src={Img} alt="Wholesale" />
      </div>
    </section>
  );
};

export default Shipping;
