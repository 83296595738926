import { useNavigate } from "react-router-dom";
import "./About.css";

const About = () => {
  const navigate = useNavigate();

  return (
    <section
      id="About"
      className="About-container"
      role="contentinfo"
      aria-label="About"
    >
      <div className="About-section1">
        <h2 className="About-title">Why Choose Beled</h2>
        <h3 className="About-subtitle">
          BeledCosmetics is a leading cosmetic packaging supplier based in
          China, bringing 9 years of industry expertise to the table. We offer
          comprehensive one-stop services, covering everything from research and
          development, production, and quality control to regulatory compliance,
          packaging design, and procurement.
        </h3>
        <button
          className="About-button"
          onClick={() => navigate("/services/wholesale", { replace: true })}
        >
          View Our Services
        </button>
      </div>
      <div className="About_video">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/DxvjOiznCow?playlist=DxvjOiznCow&autoplay=1&mute=1&loop=1"
          title="Welcome to BeledCosmetics Factory"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          kind="caption"
        ></iframe>
      </div>
    </section>
  );
};

export default About;
