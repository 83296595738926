import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Icon, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { sendEmail } from "../../API/emailApi";
import FacebookIcon from "../../images/icons/logo-facebook.png";
import InstagramIcon from "../../images/icons/logo-instagram.png";
import LinkedInIcon from "../../images/icons/logo-linkedin.png";
import YouTubeIcon from "../../images/icons/logo-youtube.png";
import Logo from "../../images/logo.png";
import "./Popup.css";

const Popup = (props) => {
  const { handlePopup } = props;
  const popupForm = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState("");
  const [isDone, setIsDone] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDone(false);
    setIsError(false);
    setIsErrorMessage("");
    setIsLoading(true);

    if (!name || !email || !country || !message) {
      setIsLoading(false);
      setIsError(true);
      setIsErrorMessage("Please fill all the required fields!");
      return;
    } else if (!isValidEmail(email)) {
      setIsLoading(false);
      setIsError(true);
      setIsErrorMessage("Email is invalid!");
      return;
    } else {
      await sendEmail(name, mobile, email, country, message).then(
        (response) => {
          if (response === 200) {
            setIsDone(true);
          } else {
            setIsError(true);
            setIsErrorMessage("Oops, Something went wrong..");
          }
        }
      );
    }
  };

  return (
    <div className="popup">
      <form
        className="popup__container"
        ref={popupForm}
        onSubmit={handleSubmit}
      >
        <IconButton
          aria-label="close"
          color="third"
          tabIndex={0}
          onClick={handlePopup}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <h1 className="popup__title">Get your quote</h1>
        {isDone ? (
          <div className="popup_success">
            <img className="popup__image" src={Logo} alt="Logo" />
            <h2 className="popup__subtitle">Thank you for contact us!</h2>
            <p className="popup__subtext">
              We will get back to you as soon as possible.
            </p>
          </div>
        ) : (
          <>
            <div className="popup__header">
              <p className="popup__text">
                Please fill the form below and our dedicated expert team will
                get back to you as soon as possible.
              </p>
            </div>
            <input
              required="true"
              aria-required="true"
              className="popup__form-input"
              placeholder="Your Name *"
              type="text"
              name="user_name"
              onChange={(e) => setName(e.target.value)}
              disabled={isLoading ? true : false}
            ></input>
            <input
              required="false"
              aria-required="false"
              className="popup__form-input"
              placeholder="Your Phone Number"
              type="tel"
              name="user_phone"
              onChange={(e) => setMobile(e.target.value)}
              disabled={isLoading ? true : false}
            ></input>
            <input
              required="true"
              aria-required="true"
              className="popup__form-input"
              placeholder="Your Email *"
              type="email"
              name="user_email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading ? true : false}
            ></input>
            <input
              required="true"
              aria-required="true"
              className="popup__form-input"
              placeholder="Your Country *"
              type="text"
              name="user_country"
              onChange={(e) => setCountry(e.target.value)}
              disabled={isLoading ? true : false}
            ></input>
            <textarea
              required="true"
              aria-required="true"
              className="popup__form-input-text"
              placeholder="Your Message *"
              type="text"
              name="user_message"
              spellCheck="true"
              onChange={(e) => setMessage(e.target.value)}
              disabled={isLoading ? true : false}
            />
            {isError && <p className="popup__form-isError">{isErrorMessage}</p>}
            <button
              type="submit"
              value="Send"
              disabled={isLoading ? true : false}
              className={`${
                isLoading
                  ? "popup__form-button popup__form-button-loading"
                  : "popup__form-button"
              }`}
            >
              {isLoading ? "Sending..." : "Submit"}
            </button>
          </>
        )}
      </form>
      <div className="popup__footer">
        <ul className="popup__footer-list">
          <a
            href="mailto:Sales@Beledcosmetics.com"
            target="_blank"
            rel="noreferrer"
            aria-label="Email link"
          >
            <Icon component={MailOutlineIcon} className="popup__footer-icon" />
          </a>
          <a
            href="https://wa.me/+8618018796760"
            target="_blank"
            rel="noreferrer"
            aria-label="Whatsapp link"
          >
            <Icon component={WhatsAppIcon} className="popup__footer-icon" />
          </a>
          <a
            href="https://www.youtube.com/@BeledCosmetics"
            target="_blank"
            rel="noreferrer"
            aria-label="YouTube page"
          >
            <img src={YouTubeIcon} alt="" className="popup__footer-icon" />
          </a>
          <a
            href="https://www.facebook.com/BeledCosmetics"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook page"
          >
            <img src={FacebookIcon} alt="" className="popup__footer-icon" />
          </a>
          <a
            href="https://www.instagram.com/beledcosmetics__"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram page"
          >
            <img src={InstagramIcon} alt="" className="popup__footer-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/beledcosmetics/"
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn page"
          >
            <img src={LinkedInIcon} alt="" className="popup__footer-icon" />
          </a>
        </ul>
        <p className="popup__footer-text">BELED COSMETICS Co., Ltd</p>
      </div>
    </div>
  );
};

export default Popup;
