import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { articles } from "../../../API/articles";
import "../Article.css";

const More = () => {
  const navigate = useNavigate();

  const handleClick = (article) => {
    navigate(`/blog/${article.link}`, { replace: false });
  };

  return (
    <>
      {articles &&
        articles.map((article, index) => (
          <div className="Article_Blog-container" key={index}>
            <img
              src={article.image}
              alt={article.title}
              className="Article_Blog_image"
              onClick={() => handleClick(article)}
            />
            <div className="Article_Blog_content-container">
              <h2
                className="Article_Blog_content-title"
                onClick={() => handleClick(article)}
              >
                {article.title}
              </h2>
              <span className="Article_Blog_content-span">{article.text}</span>
              <Button
                variant="contained"
                color="secondary"
                className="Article_Blog_button"
                onClick={() => handleClick(article)}
              >
                Read more
              </Button>
            </div>
          </div>
        ))}
    </>
  );
};

export default More;
