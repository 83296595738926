import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Breadcrumbs, Link, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { menuItems } from "../../API/menuItems";
import { useGetProductByCategory } from "../../hooks/useGetProductByCategory";
import "./Products.css";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Products = () => {
  let sliderRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { error, data } = useGetProductByCategory();
  const [pathList, setPathList] = useState([]);
  const [bestSellersList, setBestSellersList] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const pathArray = location.pathname
      .split("/")
      .filter((item) => item !== "");
    setPathList(pathArray);
  }, [location]);

  useEffect(() => {
    setBestSellersList(data);
  }, [data]);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    vertical: true,
    verticalSwiping: true,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    row: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          vertical: true,
          verticalSwiping: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          vertical: false,
          verticalSwiping: false,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  function SliderNextArrow() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          background: "rgba(150, 150, 150, 0.3)",
          fontSize: "50px",
          height: "30px",
          borderRadius: "0 0 20px 20px",
          zIndex: "1000",
          cursor: "pointer",
        }}
        onClick={next}
      >
        <div style={{ rotate: "90deg", padding: 0, margin: 0 }}>
          <ArrowBackIosIcon />
        </div>
      </div>
    );
  }

  function SliderPrevArrow() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "rgba(150, 150, 150, 0.3)",
          fontSize: "50px",
          height: "30px",
          borderRadius: " 20px 20px 0 0",
          zIndex: "1000",
          cursor: "pointer",
        }}
        onClick={previous}
      >
        <div style={{ rotate: "-90deg", padding: 0, margin: 0 }}>
          <ArrowBackIosIcon />
        </div>
      </div>
    );
  }

  const handleNavigate = (item) => {
    navigate(`../Products/${item.category}/${item.id}`, { replace: false });
  };

  return (
    <section className="Products">
      <div className="Products_header">
        <h1 className="Products_title">Products</h1>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          {pathList.map((path, index) =>
            index < pathList.length - 1 ? (
              <NavLink
                key={index}
                className="Item_navlink"
                to={path === "Products" ? "/Products" : path}
                replace={true}
              >
                {path}
              </NavLink>
            ) : (
              <Typography key={index} color="text.primary">
                {path}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </div>
      <div className="Products_container">
        <div className="Products_side-container">
          <div className="Products_categories-container">
            <h2 className="Products_categories-title">Products Categories</h2>
            <div className="Products_categories-list">
              {menuItems.map((menu) => {
                if (menu.title === "Products" && menu.submenu) {
                  return menu.submenu.map((submenuItem, subIndex) => (
                    <p
                      key={subIndex}
                      className="Products_categories_list-item"
                      onClick={() => navigate(submenuItem.url)}
                    >
                      {submenuItem.title}
                    </p>
                  ));
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className="Products_topsales-container">
            <h2 className="Products_categories-title">Best Sellers</h2>
            {windowDimensions.width <= 600 ? "" : <SliderNextArrow />}
            {bestSellersList?.length > 0 ? (
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {bestSellersList.map((item) => {
                  return (
                    <div className="Product_item" key={item?.id}>
                      {item?.images[0] ? (
                        <img
                          src={item?.images[0]}
                          alt="Product"
                          className="Item_image"
                          onClick={() => handleNavigate(item)}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={200}
                          height={200}
                        />
                      )}
                      <h4
                        className="Product_item-title"
                        onClick={() => handleNavigate(item)}
                      >
                        {item?.title}
                      </h4>
                      <p className="Product_item-subtitle">MOQ {item?.MOQ}</p>
                    </div>
                  );
                })}
              </Slider>
            ) : error ? (
              <p>Oops, Something went wrong..</p>
            ) : (
              <p>No products found!</p>
            )}
            {windowDimensions.width <= 600 ? "" : <SliderPrevArrow />}
          </div>
        </div>
        <Outlet />
      </div>
      <Stack spacing={2} className="Products_pagination"></Stack>
      <p className="Products_note">
        Can't find what you are looking for?{" "}
        <a className="Products_link" href="/contact">
          Contact Us
        </a>
      </p>
    </section>
  );
};

export default Products;
