import React, { createContext, useContext, useEffect, useState } from "react";

const QuoteListContext = createContext();

export const QuoteListProvider = ({ children }) => {
  const [quoteList, setQuoteList] = useState([]);

  useEffect(() => {
    const storedList = localStorage.getItem("quoteList");
    if (storedList) {
      setQuoteList(JSON.parse(storedList));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("quoteList", JSON.stringify(quoteList));
  }, [quoteList]);

  const clearList = () => {
    setQuoteList([]);
  };

  const addItem = (item) => {
    setQuoteList([...quoteList, item]);
  };

  const removeItem = (index) => {
    const updatedList = [...quoteList];
    updatedList.splice(index, 1);
    setQuoteList(updatedList);
  };

  return (
    <QuoteListContext.Provider
      value={{ quoteList, clearList, addItem, removeItem }}
    >
      {children}
    </QuoteListContext.Provider>
  );
};

export const useQuoteList = () => {
  return useContext(QuoteListContext);
};
