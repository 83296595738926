import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "../../API/menuItems";
import { useQuoteList } from "../../context/ProductsListContext";
import Logo from "../../images/logo.png";
import MenuItems from "./MenuItems";
import "./Navbar.css";

const Navbar = ({ setIsPopupOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { quoteList } = useQuoteList();
  const [showNavbar, setShowNavbar] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    const string = location.pathname;
    setActiveLink(string === "/" ? "home" : string);
    window.scrollTo(0, 0);
    setShowNavbar(false);
  }, [location]);

  useEffect(() => {
    const innernav = document.querySelector(".menus");

    if (showNavbar === true) {
      innernav.style.left = "0";
    } else {
      innernav.style.left = "-1000px";
    }
  }, [showNavbar]);

  const handleNavbarClick = () => {
    setShowNavbar(!showNavbar);
  };

  const handleQuoteClick = () => {
    if (quoteList.length === 0) {
      setIsPopupOpen(true);
    } else {
      toggleDrawer();
    }
  };

  return (
    <div className="navbar" role="navigation">
      <div
        className={`${showNavbar ? "hamburger1" : "hamburger"}`}
        onClick={handleNavbarClick}
      ></div>

      <button className="logo-container" onClick={() => navigate("")}>
        <img className="logo" src={Logo} alt="Logo" />
      </button>

      <nav>
        <ul className="menus">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <MenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                activeLink={activeLink}
              />
            );
          })}
        </ul>
      </nav>

      <Badge color="error" badgeContent={quoteList.length}>
        <button className="nav-button" onClick={handleQuoteClick}>
          Get your quote
        </button>
      </Badge>
    </div>
  );
};

export default Navbar;
