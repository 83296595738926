import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./LmoqPopup.css";

const LmoqPopup = ({ setShowPopup }) => {
  const navigate = useNavigate();
  const state = "500";

  return (
    <div className="LmoqPopup-container" aria-modal="true" role="dialog">
      <div className="LmoqPopup">
        <IconButton
          aria-label="close"
          color="primary"
          tabIndex={0}
          onClick={() => setShowPopup(false)}
          sx={{ position: "absolute", right: 15, top: 15 }}
        >
          <CloseIcon />
        </IconButton>
        <h1 className="LmoqPopup__title">
          Seeking low MOQ? <br /> Look no further!
        </h1>
        <h2 className="LmoqPopup__text">
          Discover our products with starting quantities as low as 500 pieces.
        </h2>
        <button
          className="LmoqPopup__button"
          aria-label="Explore products"
          tabIndex={0}
          onClick={() => {
            navigate("/Products", {
              replace: false,
              state: { state },
              keyword: { state },
            });
            setShowPopup(false);
          }}
        >
          Explore now →
        </button>
      </div>
    </div>
  );
};

export default LmoqPopup;
