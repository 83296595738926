import { useQuery } from "react-query";
import { getProductByCategory } from "../API/firestoreApi";

export const useGetProductByCategory = () => {
  return useQuery({
    queryFn: () => getProductByCategory(),
    queryKey: ["getProductByCategory"],
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
